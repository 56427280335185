import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Hero from "~/components/Hero";
import TourCarousel from "~/components/TourCarousel";
import { StaticImage } from "gatsby-plugin-image";
import open from "~/images/Open-Quote.svg";
import close from "~/images/End-Quote.svg";
import Card from "~/components/styled/card";

const IndexPage = ({ data }) => {
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);
	const indiaTours = flatTours.filter(
		//(dest) => dest.destination.name === "India",
		(type) => {
			return type.tourType === "multiday" && type.destination.name === "India";
		}
	);
	const asiaTours = flatTours.filter((dest) => dest.destination.name === "Asia");
	const asia = [
		{
			name: "Vietnam",
			to: "/destinations/vietnam",
		},
		{
			name: "Cambodia",
			to: "/destinations/cambodia",
		},
		{
			name: "Thailand",
			to: "/destinations/thailand",
		},
		{
			name: "Laos",
			to: "/destinations/laos",
		},
		{
			name: "Sri Lanka",
			to: "/destinations/sri-lanka",
		},
		{
			name: "Bhutan",
			to: "/destinations/bhutan",
		},
		{
			name: "Nepal",
			to: "/destinations/nepal",
		},
	];
	const india = [
		{
			name: "Rajasthan",
			to: "/destinations/rajasthan",
		},
		{
			name: "Kerala & South India",
			to: "/destinations/kerala",
		},
		{
			name: "Goa",
			to: "/destinations/goa",
		},
		{
			name: "Himalayas",
			to: "/destinations/himalayas",
		},
		{
			name: "Northeast",
			to: "/destinations/northeast-india",
		},
	];
	//const seo = { title: "Categories" }
	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="Family Experiences in India, Asia & Europe"
				description="Roots & Routes specializes in crafting unique family experiences to India, Asia and Europe. We’re all about small details.
				"
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in",
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com",
				// 	},
				// ]}
			/>
			<Hero />

			{/* <section className="mx-4 my-16 lg:my-28 lg:mx-24">
				<div className="flex">
					<div className="w-20 h-20">
						<img src={open} alt="open quote" />
					</div>
					<div>
						<p className="prose sm:prose-xl lg:prose-2xl px-2 md:px-4">
							Founded in 2010 during a 100km bumpy ride in India, Roots & Routes
							specializes in crafting active experiences to India and Asia-at-Large.
							We’re all about small details.
						</p>
					</div>
					<div className="w-20 h-20">
						<img src={close} alt="close quote" />
					</div>
				</div>
			</section> */}

			{/* activities section */}
			{/* <section className="">
				<h2 className="mb-4">Ease back into a group adventure</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<Card>
						<Link to="/activities/cycling">
							<div className="">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bangalore-bike-tour-6.JPG"
									alt="active experiences"
									layout="FULL_WIDTH"
									aspectRatio="1.8"
								/>
							</div>
							<p className="px-4 py-4 font-medium text-xl hover:text-primary">
								Cycling Adventures
							</p>
							<p className="px-4 pb-4 prose prose-sm">
								Led by our top cycling guides, the folks who steer you towards unique
								and enriching experience, you get to ride from one place to another
								through our immaculately planned backroads with support van always near
								at hand. If at any point riding seems a little hard, the van takes you
								for as long or as little as you wish it to.
							</p>
						</Link>
					</Card> */}
			{/* <Card>
						<Link to="/activities/multi-activity">
							<div className="">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/south-india-bike-tour-8.jpeg"
									alt="active experiences"
									layout="FULL_WIDTH"
									aspectRatio="1.8"
								/>
							</div>
							<p className="px-4 py-4 font-medium text-xl hover:text-primary">
								Multi-activity Experience
							</p>
							<p className="px-4 pb-4 prose prose-sm">
								Immerse yourselves into a region by balancing culture with local
								activities. Here, you get to enjoy in-destination activities; biking,
								hiking, walking, kayaking, yoga, birding, and more while exploring local
								culture and history.
							</p>
						</Link>
					</Card> */}
			{/* <Card>
						<Link to="/specialities/family">
							<div className="">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/udaipur-bike-tour-4.jpg"
									alt="family adventures"
									layout="FULL_WIDTH"
									aspectRatio="1.8"
								/>
							</div>
							<p className="px-4 py-4 font-medium text-xl hover:text-primary">
								Family Adventures
							</p>
							<p className="px-4 pb-4 prose prose-sm">
								Our family adventures are designed to create ever lasting memories and
								offers perfect bonding opportunity in the outdoors with your kids (9-12
								years) and teens (13-16 years). Most tours offer a mix of education and
								outdoor activities; cycling, hiking, kayaking, rafting, rappelling,
								birding, swimming and more.
							</p>
						</Link>
					</Card>
				</div>
			</section> */}

			{/* India section */}
			{/* <section className="mb-12">
				<div className="flex flex-col sm:flex-row sm:justify-between mt-24 mb-4">
					<h2 className="">Top active experiences in India</h2>
					<Link to="/destinations/india" className="my-4 sm:my-0">
						<span className="px-5 py-2   text-sm border border-primary text-primary bg-white rounded-full">
							View All
						</span>
					</Link>
				</div>

				<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-8 pb-12">
					{india.map((reg) => {
						return (
							<Link key={reg.name} to={reg.to}>
								<div className="">
									<span className="border border-primary p-4 md:p-6 rounded-lg">
										{reg.name}
									</span>
								</div>
							</Link>
						);
					})}
				</div>

				<TourCarousel tours={indiaTours} />
			</section> */}

			{/* Asia section */}
			<section className="mb-12">
				<div className="flex flex-col sm:flex-row sm:justify-between mt-24 mb-4">
					<h2 className="">Top family experiences</h2>
					<Link to="/destinations/asia" className="my-4 sm:my-0">
						<span className="px-5 py-2   text-sm border border-primary text-primary bg-white rounded-full">
							View All
						</span>
					</Link>
				</div>
				{/* Region List Start */}
				{/* <div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-8 pb-12">
					{asia.map((reg) => {
						return (
							<Link key={reg.name} to={reg.to}>
								<div className="">
									<span className="border border-primary p-4 md:p-6 rounded-lg">
										{reg.name}
									</span>
								</div>
							</Link>
						);
					})}
				</div> */}
				{/* Region List End */}
				<TourCarousel tours={flatTours} />
			</section>
			{/* private section */}
			{/* <section>
				<div className="flex flex-col lg:flex-row bg-secondary w-auto h-auto p-10 lg:p-20 rounded-lg my-20">
					<div className="lg:w-1/2">
						<StaticImage
							className="rounded-lg shadow-lg transform"
							src="../images/bangalore-bike-tour-8.JPG"
							alt="private adventures"
							layout="FULL_WIDTH"
							aspectRatio="1.5"
						/>
					</div>
					<div className="mt-4 lg:w-1/2 lg:pl-20">
						<h3 className=" mb-4">Book a Private Experience</h3>
						<p className="mb-4 prose">
							We offer private departures on almost all our itineraries, and for groups
							of six or more, they cost the same as our standard trips!
						</p>
						<p className="prose">
							If you don’t find a suitable standard itinerary then you can always
							design your own private adventure or customize an existing itinerary.
						</p>
					</div>
				</div>
			</section> */}
		</Layout>
	);
};

export const query = graphql`
	query ActivitiesQuery {
		allStrapiTour(sort: { fields: price }) {
			edges {
				node {
					id
					title
					price
					duration
					tourType
					slug
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}
					quickfacts {
						lodging
						level
					}

					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}
	}
`;

export default IndexPage;
