import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Hero = () => {
	return (
		<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
			<div className="max-w-7xl mx-auto">
				<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-lg lg:w-full lg:pb-28 xl:pb-32">
					<div className="mt-10 mx-auto sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
						<div className="sm:text-center lg:text-left">
							<h1 className=" text-gray-900">
								<span className="block xl:inline font-normal">
									Thoughtfully crafted
								</span>{" "}
								<span className="block text-primary xl:inline font-semibold">
									family experiences
								</span>
							</h1>
							{/* <p className="mt-3 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
								Designed for those who love to stay active and curious while traveling
								and enjoy the camaraderie of exploring the world with like-minded
								people.
							</p> */}
							<p className="mt-3 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
								Designed with families in mind, our journeys go beyond hiking, inspiring
								curiosity and fostering confidence in children while creating lasting
								memories for everyone.
							</p>
							<div className="mt-5 sm:mt-10 sm:flex sm:justify-center lg:justify-start">
								{/* <div className="">
									<Link
										to="/upcoming-rides"
										className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-primary hover:bg-primary md:py-4 md:px-8"
									>
										Upcoming Trips
									</Link>
								</div>
								<div className="mt-3 sm:mt-0 sm:ml-3">
									<Link
										to="/private"
										className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-primary border-primary md:py-4 md:px-8"
									>
										Design trip
									</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 ">
				<StaticImage
					className="rounded-lg shadow-lg transform border border-gray-200"
					src="../images/family-hike.jpg"
					alt="Cycling Rajasthan"
					layout="FULL_WIDTH"
					aspectRatio="1.2"
					loading="eager"
				/>
				{/* <img
            className="border border-gray-200	 rounded-lg shadow-lg h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="https://i0.wp.com/abtprod.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/04/12134531/taste-of-kerala-51.jpg?resize=1500%2C630&ssl=1"
            alt=""
          /> */}
			</div>
		</section>
	);
};

export default Hero;
