import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Card from "~/components/styled/card";
import Image from "~/components/image";
import CardSlider from "~/components/styled/CardSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { formatPrice } from "~/helpers/currency-formatter";

const TourCarousel = ({ tours, gridCols }) => {
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		arrows: false,
		slidesToShow: 3.25,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2.25,
					slidesToScroll: 2,
					arrows: false,
					infinite: false,
					dots: true,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.25,
					slidesToScroll: 1,
					arrows: false,
					infinite: false,
					dots: false,
					initialSlide: 0,
				},
			},
		],
	};
	return (
		<div className="">
			<Slider {...settings}>
				{tours.map((tour) => {
					return (
						<div className="pr-6 pl-1 mb-4">
							<Card key={tour.id}>
								<Link to={`/tours/${tour.slug}`} key={tour.id}>
									<Image
										alt="Tour Image"
										className="rounded-t-md transform border-gray-200	 border-b"
										image={tour.featuredImage}
									/>
									{/* <CardSlider slides={tour.slider} /> */}

									<div className="px-4 py-4 ">
										<div className="mb-0.5">
											<p className="font-medium capitalize truncate hover:text-primary">
												{tour.title}
											</p>
										</div>
										<div className="flex mb-4">
											{/* {node.destinations && node.destinations.map(destinations => {
                  return (
                  <div key={destinations.id}>
                  <p className="text-xs border rounded-full p-0.5 px-2 mr-1">{destinations.name}</p>
                  </div>
                )})} */}
											{tour.regions &&
												tour.regions.map((regions) => {
													return (
														<div key={regions.id}>
															<p className="text-xs border rounded-full truncate p-0.5 px-2 mr-1">
																{regions.name}
															</p>
														</div>
													);
												})}
											{tour.activity && (
												<p className="text-xs border rounded-full p-0.5 px-2">
													{tour.activity.name}
												</p>
											)}
										</div>
										<div>
											<p className="text-sm font-semibold self-end">
												From {tour.price && formatPrice(tour.price)}
											</p>
										</div>
										<div className="flex items-baseline">
											<div className="text-sm text-gray-500 lowercase">
												{tour.duration}
											</div>
											<div>
												{tour.quickfacts.lodging && (
													<span className="mx-1 text-sm text-gray-500"> | </span>
												)}
											</div>
											<div>
												{tour.quickfacts.lodging && (
													<span className="text-sm text-gray-500 lowercase">
														{tour.quickfacts.lodging} inns
													</span>
												)}
											</div>
										</div>
										<div className="flex items-baseline">
											{tour.quickfacts.level && (
												<div className="text-sm text-gray-500 lowercase">
													{tour.quickfacts.level.split("_").join(" to ")}
												</div>
											)}
										</div>
									</div>
								</Link>
							</Card>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

TourCarousel.propTypes = {
	tours: PropTypes.array,
	gridCols: PropTypes.string,
};

TourCarousel.defaultProps = {
	gridCols: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
};

export default TourCarousel;
